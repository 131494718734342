import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const TopNavigation = () => {
  const { t } = useTranslation(); //['translation', 'mainTeaserSection']
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const openHamburgerOverlay = () => { setIsHamburgerOpen(true); };
  const closeHamburgerOverlay = () => { setIsHamburgerOpen(false); };

  return (
    <nav id="topnav" className="absolute z-50 bg-transparent w-screen flex items-center justify-end fixed p-4">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between p-4">
            <a id="home" href="/" className="menuItem flex items-center space-x-3 absolute w-126 h-63 bg-white rounded-full left-0 transform -translate-x-9 z-10">
                <img src="/static/img/AIME-API_logo_wht.svg" className="h-9 left-0 transform translate-x-50" alt="AIME-API Logo" />
                <span className="self-center hidden text-2xl font-semibold whitespace-nowrap">AIME API</span>
            </a>
            <div className="flex md:order-2 space-x-3 md:space-x-0 justify-end">
                <button type="button" className="inline-flex items-center p-2 w-10 w-20 h-10 justify-center text-sm text-aime_darkblue rounded-3xl bg-aime_green hover:ring-aime_blue active:bg-aime_orange" onClick={openHamburgerOverlay}>
                    <span className="sr-only">{t('navigation.openMainMenu')}</span>
                    <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button>
            </div>
            {/* <div className="items-center hidden justify-end w-full md:flex md:w-auto md:order-1">
                <ul className="flex flex-col text-md font-medium md:space-x-8 md:flex-row">
                    <li>
                        <a id="llama3-chat" href="/llama3-chat/" className="menuItem demo-link block py-1 px-3 font-bold rounded-3xl text-aime_darkblue bg-aime_red hover:bg-aime_green">Llama 3 Chat</a>
                    </li>
                    <li>
                        <a id="llama3-chat" href="/mixtral-chat/" className="menuItem demo-link block py-1 px-3 font-bold rounded-3xl text-aime_darkblue bg-aime_green hover:bg-aime_darkblue hover:text-white">Mixtral 8x7B Chat</a>
                    </li>
                    <li>
                        <a id="sdxl-txt2img" href="/sdxl-txt2img/" className="menuItem demo-link block py-1 px-3 font-bold rounded-3xl text-aime_darkblue bg-aime_orange hover:bg-aime_green">Stable Diffusion XL</a>
                    </li>
                    <li>
                        <a id="sc-m4tv2" href="/sc-m4tv2/" className="menuItem demo-link block py-1 px-3 font-bold rounded-3xl text-aime_darkblue bg-aime_blue hover:bg-aime_green">Seamless Communication</a>
                    </li>
                    <li>
                        <a href="/docs/index.html" className="text-aime_darkblue bg-aime_green block font-bold rounded-3xl px-3 py-1 text-center hover:bg-aime_darkblue hover:text-white">Docs</a>
                    </li>
                </ul>
            </div> */}
        </div>

        {isHamburgerOpen && (
        <div id="hamburger-menu-overlay" className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-90 z-40">
            <button id="closeMenu" className="absolute top-4 right-4 text-gray-600 hover:text-gray-800" onClick={closeHamburgerOverlay}>
                <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </button>
            <ul className="flex flex-col text-lg font-medium">
                <li className="m-3">
                    <a href="/llama3-chat/" className="demo-link block py-1 px-4 text-center font-bold rounded-3xl text-aime_darkblue bg-aime_red hover:bg-aime_green" onClick={closeHamburgerOverlay}>Llama 3 Chat</a>
                </li>
                <li className="m-3">
                    <a href="/mixtral-chat/" className="demo-link block py-1 px-4 text-center font-bold rounded-3xl text-white bg-aime_darkblue hover:bg-aime_green" onClick={closeHamburgerOverlay}>Mixtral 8x7B Chat</a>
                </li>
                <li className="m-3">
                    <a href="/sdxl-txt2img" className="demo-link block py-1 px-4 text-center font-bold rounded-3xl text-aime_darkblue bg-aime_orange hover:bg-aime_green" onClick={closeHamburgerOverlay}>Stable Diffusion XL</a>
                </li>
                <li className="m-3">
                    <a href="/sc-m4tv2" className="demo-link block py-1 px-4 text-center font-bold rounded-3xl text-aime_darkblue bg-aime_blue hover:bg-aime_green" onClick={closeHamburgerOverlay}>Seamless Communication</a>
                </li>
                <li className="m-3">
                    <a href="/docs/index.html" className="text-aime_darkblue bg-aime_green block rounded-3xl px-4 py-1 text-center font-bold hover:bg-aime_darkblue hover:text-white" onClick={closeHamburgerOverlay}>Docs</a>
                </li>
            </ul>
        </div>
        )}

    </nav>
    
  );
};

export default TopNavigation;
