import React from 'react';
import { useTranslation } from 'react-i18next';

import EndpointCardElement from './EndpointCardElement';

const EndpointsSection = () => {
  const { t } = useTranslation();

  // style={{backgroundImage: "url('/static/img/clrs.jpg')"}}
  return (
    <section>
        <div className="bg-transparent max-w-screen-xl px-12 py-8 mx-auto mb-2 space-y-8 lg:py-24 lg:px-6 bg-cover bg-center">
          <h2 className="text-3xl font-extrabold tracking-tight">{t('endpointSection.endpointSectionTitle')}</h2>
          <p className="lg:text-xl">{t('endpointSection.enpointSectionParagraph')}</p>
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <EndpointCardElement 
              endpointTitle={t('endpointSection.endpointCard.lama3Chat.title')} 
              endpointDescription={t('endpointSection.endpointCard.lama3Chat.description')} 
              imagePath={t('endpointSection.endpointCard.lama3Chat.imagePath')} 
              demoLinkPath={t('endpointSection.endpointCard.lama3Chat.demoLinkPath')}
              codeLinkPath={t('endpointSection.endpointCard.lama3Chat.codeLinkPath')}
            />
            <EndpointCardElement 
              endpointTitle={t('endpointSection.endpointCard.mixtralChat.title')} 
              endpointDescription={t('endpointSection.endpointCard.mixtralChat.description')} 
              imagePath={t('endpointSection.endpointCard.mixtralChat.imagePath')} 
              demoLinkPath={t('endpointSection.endpointCard.mixtralChat.demoLinkPath')}
              codeLinkPath={t('endpointSection.endpointCard.mixtralChat.codeLinkPath')}
            />
            {/* <EndpointCardElement 
              endpointTitle={t('endpointSection.endpointCard.stableDiffusion3.title')} 
              endpointDescription={t('endpointSection.endpointCard.stableDiffusion3.description')} 
              imagePath={t('endpointSection.endpointCard.stableDiffusion3.imagePath')} 
              demoLinkPath={t('endpointSection.endpointCard.stableDiffusion3.demoLinkPath')}
              codeLinkPath={t('endpointSection.endpointCard.stableDiffusion3.codeLinkPath')}
            /> */}
            <EndpointCardElement
              endpointTitle={t('endpointSection.endpointCard.stableDiffusionXL.title')} 
              endpointDescription={t('endpointSection.endpointCard.stableDiffusionXL.description')} 
              imagePath={t('endpointSection.endpointCard.stableDiffusionXL.imagePath')} 
              demoLinkPath={t('endpointSection.endpointCard.stableDiffusionXL.demoLinkPath')}
              codeLinkPath={t('endpointSection.endpointCard.stableDiffusionXL.codeLinkPath')}
            />
            <EndpointCardElement
              endpointTitle={t('endpointSection.endpointCard.seamlessCommunication.title')} 
              endpointDescription={t('endpointSection.endpointCard.seamlessCommunication.description')} 
              imagePath={t('endpointSection.endpointCard.seamlessCommunication.imagePath')} 
              demoLinkPath={t('endpointSection.endpointCard.seamlessCommunication.demoLinkPath')}
              codeLinkPath={t('endpointSection.endpointCard.seamlessCommunication.codeLinkPath')}
            />
            {/* <EndpointCardElement 
              endpointTitle={t('endpointSection.endpointCard.lama2Chat.title')} 
              endpointDescription={t('endpointSection.endpointCard.lama2Chat.description')} 
              imagePath={t('endpointSection.endpointCard.lama2Chat.imagePath')} 
              demoLinkPath={t('endpointSection.endpointCard.lama2Chat.demoLinkPath')}
              codeLinkPath={t('endpointSection.endpointCard.lama2Chat.codeLinkPath')}
            /> */}
          </div>

          <div className="text-small flex items-center space-x-4">
            <span>{t('endpointSection.otherModels')}</span>
            <ul className="flex list-none p-0 m-0 space-x-4">
              <li className="mr-4"><a href={t('endpointSection.endpointCard.lama2Chat.codeLinkPath')} target="_blank" rel="noreferrer">{t('endpointSection.endpointCard.lama2Chat.title')}</a></li>
              {/* <li className="pipe">|</li> */}
            </ul>
          </div>

        </div>
    </section>
  );
};

export default EndpointsSection;
